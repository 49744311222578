//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "Loader",
};
